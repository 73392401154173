import {Modifier} from "./Modifier";

/* Используется монстром Boar 
 * даёт x2 скорость
 */
export class BoarSpecialAbility extends Modifier{
	constructor() {
		super(BoarSpecialAbility.name, 0, 0, 0, 1, 0, null);
	}
}
Object.defineProperty(BoarSpecialAbility, "name", { value: 'BoarSpecialAbility', writable: false }); //fix production minification class names